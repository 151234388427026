/* @flow */

import { useLocation } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";

type Step = {
  id: string,
  key: number,
  link: string,
  title: string,
  titleLong: string,
};

const useCheckoutSteps = (): {
  steps: Array<Step>,
  currentStep: Step,
} => {
  const t = useTranslate();
  const location = useLocation();

  const steps: Array<Step> = [
    {
      id: "cart",
      key: 1,
      link: "/checkout/cart",
      title: t("CHECKOUT.STEPS.CART.SHORT"),
      titleLong: t("CHECKOUT.STEPS.CART.LONG"),
    },
    {
      id: "shipping",
      key: 2,
      link: "/checkout/shipping",
      title: t("CHECKOUT.STEPS.SHIPPING.SHORT"),
      titleLong: t("CHECKOUT.STEPS.SHIPPING.LONG"),
    },
    {
      id: "overview",
      key: 3,
      link: "/checkout/overview",
      title: t("CHECKOUT.STEPS.OVERVIEW.SHORT"),
      titleLong: t("CHECKOUT.STEPS.OVERVIEW.LONG"),

    },
  ];
  const currentStep = steps.find(s => s.link === location.pathname) || {};

  return { steps, currentStep };
};

export default useCheckoutSteps;
