/* @flow */

import type { ProductCardProduct } from "shop-state/types";

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import { HomeData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { formatMeta } from "helpers/get-meta";
import Hero from "components/Hero";
import Button from "components/Button";
import LoadingView from "components/LoadingView";
import ProductPreview from "components/ProductPreview";
import Carousel from "components/Carousel";
import ProductCard from "components/ProductCard";
import { useBreakpoints } from "helpers/use-breakpoints";

import styles from "./styles.scss";

type CategoryCarouselProps = {
  category: {
    name: string,
    url: string,
    products: {
      items: Array<ProductCardProduct>,
    },
  },
};

const HomeView = (): React$Node => {
  const home = useData(HomeData);
  const { getNumberOfDisplayedItems } = useBreakpoints(styles);
  const {
    categories,
    info: { defaultTitle, defaultDescription },
  } = useContext(StoreInfoContext);

  if (home.state !== "LOADED") {
    return <LoadingView />;
  }

  const meta = formatMeta({
    title: defaultTitle,
    description: defaultDescription,
  });

  const getAmountOfDisplayedCategories = () => {
    const breakpointColumnMapping = {
      medium: 1,
      large: 3,
    };
    return getNumberOfDisplayedItems(breakpointColumnMapping, 3);
  };

  const CategoryCarousel = ({ category }: CategoryCarouselProps) => {
    const t = useTranslate();

    return (
      <section className={styles.section}>
        <header className={styles.header}>
          {category.name && <h2>{category.name}</h2>}
          <Button variant="dark" to={category.url}>{t("HOMEVIEW.GO_TO")}</Button>
        </header>
        <div className={styles.productList}>
          <Carousel
            className={styles.carousel}
            slidesToShow={getAmountOfDisplayedCategories()}
            items={category.products.items.map((p, i) => (
              <div key={p.sku} className={styles.card}>
                <ProductCard
                  product={p}
                  position={i}
                  breadcrumbLinks={[
                    {
                      pathname: category.url,
                      title: category.name,
                      hint: {
                        name: category.name,
                      },
                    },
                  ]}
                  list={category.name}
                />
              </div>
            ))}
          />
        </div>
      </section>
    );
  };

  return (
    <div className={styles.block}>
      <Helmet title={meta.title} meta={meta.data} />
      {home.data.hero && (
        <Hero block={home.data.hero.block} buttonModifier="primary" />
      )}

      <div className={styles.content}>
        <div className={styles.categoriesWrapper}>
          <div className={styles.categories}>
            {categories.map(x => (
              <div key={x.url} className={styles.col}>
                <Link className={styles.item} to={x.url}>
                  <img src={x.image.src} />
                  <h3>{x.name}</h3>
                </Link>
              </div>
            ))}
          </div>
        </div>

        {home.data.productPreview && (
          <ProductPreview product={home.data.productPreview.product} />
        )}
        {home.data.popularProducts && home.data.popularProducts.category && (
          <CategoryCarousel category={home.data.popularProducts.category} />
        )}
        {home.data.categoryPreview1 && home.data.categoryPreview1.category && (
          <CategoryCarousel category={home.data.categoryPreview1.category} />
        )}
        {home.data.categoryPreview2 && home.data.categoryPreview2.category && (
          <CategoryCarousel category={home.data.categoryPreview2.category} />
        )}

      </div>
    </div>
  );
};

export default HomeView;
