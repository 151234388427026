/* @flow */

import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { Route, Switch, useHistory } from "react-router";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import { useData } from "crustate/react";
import { getQuoteData } from "state/quote";
import { QuoteData } from "data";
import Wrapper from "components/Wrapper";
import Header from "components/AppHeader/StepHeader/checkout-header";
import Button from "components/Button";
import useCheckoutSteps from "helpers/use-checkout-steps";
import ArrowIcon from "icons/arrow.svg";

import Cart from "components/CheckoutView/Cart";
import Step1 from "components/CheckoutView/Step1";
import Step2 from "components/CheckoutView/Step2";

import styles from "./styles.scss";

const CheckoutView = (): React$Node => {
  const t = useTranslate();
  const history = useHistory();
  const gaContext = useContext(AnalyticsContext);
  const quoteData = useData(QuoteData);
  const quoteState = useData(QuoteData);
  const quote = getQuoteData(quoteState);
  const [open, setOpen] = useState(true);
  const [visited, setVisited] = useState([]);
  const { currentStep } = useCheckoutSteps();
  const step = currentStep.key;

  useEffect(() => {
    setVisited([...visited, step]);
  }, []);

  useEffect(() => {
    if (step === 0) {
      return;
    }

    if (step === 1) {
      if (quote) {
        gaContext.registerBeginCheckoutProcess(
          quote.items,
          quote.grandTotal.incVat
        );
      }
    }

    gaContext.registerCheckoutStep(step, currentStep.id);
  }, [step]);

  if (quoteData.state === "LOADING" || !quote) {
    return null;
  }

  if (quoteData.state === "LOADED" && quoteData.data.items.length === 0) {
    return (
      <div className={styles.block}>
        <Header />

        <Wrapper>
          <header className={styles.header}>
            <h1 className={styles.heading}>{t("CART.EMPTY")}</h1>
            <Button
              className={cn(styles.back, "link")}
              slotLeft={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
              onClick={() => history.push("/")}
            >
              {t("CHECKOUT.CONTINUE_SHOPPING")}
            </Button>
          </header>
        </Wrapper>
      </div>
    );
  }

  const totalItems = quote.items.reduce((total, item) => total + item.qty, 0);

  return (
    <div className={styles.block}>
      <Helmet
        title={currentStep ? currentStep.title : t("CHECKOUT.STEPS.CART.SHORT")}
      />

      <Header />

      <Wrapper>
        <header className={styles.header}>
          <h1 className={styles.heading}>
            {currentStep ? currentStep.titleLong : t("CHECKOUT.STEPS.CART.LONG")}{currentStep.id === "cart" ? ` (${totalItems})` : ""}
          </h1>
          <Button
            className={cn("link", styles.back)}
            slotLeft={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
            onClick={() => history.push("/")}
          >
            {t("CHECKOUT.CONTINUE_SHOPPING")}
          </Button>
        </header>

        <Switch>
          <Route exact path="/checkout/cart" render={() => <Cart />} />
          <Route exact path="/checkout/shipping" render={() => <Step1 step={1} open={open} setOpen={setOpen} quoteData={quoteData} />} />
          <Route exact path="/checkout/overview" render={() => <Step2 step={3} open={open} setOpen={setOpen} quoteData={quoteData} />} />
        </Switch>
      </Wrapper>
    </div>
  );
};

export default CheckoutView;
