/* @flow */

import React, { useEffect, useContext } from "react";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { useData, useSendMessage } from "crustate/react";
import { getQuoteData, setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";
import { QuoteData, CmsData } from "data";
import useFormat from "helpers/use-format";
import useCookie from "helpers/use-cookie";
import { StripeCheckoutContainer } from "@crossroads/shop-views";

import CmsPageView from "components/CmsPageView";
import PaymentMethodsCards from "../PaymentMethodsCards";

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const Step2 = ({ open, setOpen }: Props): React$Node => {
  const storeInfo = useContext(StoreInfoContext);
  const client = useClient();
  const cmsData = useData(CmsData);
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(useData(QuoteData));
  const sendMessage = useSendMessage();
  const { formatPrice } = useFormat();
  const discountCodeField = true;

  const cookieOpts = {
    domain: `.${typeof window !== "undefined" ? window.location.host : ""}`,
    expires: 3650,
  };
  const [couponCode] = useCookie("coupon", "", cookieOpts);

  useEffect(() => {
    if (couponCode) {
      sendMessage(setQuoteDiscountCode(couponCode));
    }
  }, [couponCode]);

  // Open summary when this route renders
  useEffect(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <StripeCheckoutContainer
      open={open}
      setOpen={setOpen}
      enableDiscountCode={discountCodeField}
      formatPrice={formatPrice}
      client={client}
      storeInfo={storeInfo}
      termsData={cmsData}
      quote={quote}
      quoteData={quoteData}
      setQuoteDiscountCode={setQuoteDiscountCode}
      removeQuoteDiscountCode={removeQuoteDiscountCode}
      agreement={cmsData.state === "LOADED" ?
        <CmsPageView cmsPage={cmsData.data} /> : null}
      paymentMethodsCards={<PaymentMethodsCards />}
      browserProcess={process.browser}
    />
  );
};

export default Step2;
