/* @flow */

import type { ProductCardProduct, GAProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { useRef, useContext, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext, ProductLink } from "@crossroads/analytics";
import { Square, ProductListMedia } from "@crossroads/ui-components";
import useFormat from "helpers/use-format";
import Button from "components/Button";

import styles from "./styles.scss";

type ProductCardProps = {
  product: ProductCardProduct,
  className?: string,
  list?: string,
  position?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

const ProductCard = ({
  product,
  className,
  breadcrumbLinks,
  position,
  list,
}: ProductCardProps): React$Node => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const { formatPrice } = useFormat();
  const gaRef: { current: null | Element } = useRef(null);
  // Default to smallest image
  const [imageSrc, setImageSrc] = useState<string>("");
  const { price: { incVat: price }, attributes: { msrp } } = product;
  const discount = Math.floor((msrp - price) / msrp * 100);

  const mapGaObject = (product: ProductCardProduct): GAProduct => {
    const gaObject = {
      item_id: product.sku, // eslint-disable-line camelcase
      item_name: product.name, // eslint-disable-line camelcase
      item_brand: product.attributes.manufacturer, // eslint-disable-line camelcase
      price: product.price.incVat,
      index: Number(position) + 1,
      item_list_name: list ?? "", // eslint-disable-line camelcase
    };

    if (product.categories === undefined || product.categories.length === 0) {
      return gaObject;
    }

    product.categories.forEach((c, i) => {
      if (i === 0) {
        gaObject.item_category = c.name; // eslint-disable-line camelcase
      }
      else {
        gaObject[`item_category${i + 1}`] = c.name;
      }
    });

    return gaObject;
  };

  useEffect(() => {
    const gaObject = mapGaObject(product);

    if (!gaRef.current) {
      return;
    }

    gaContext.register(gaRef.current, gaObject);
  }, [gaRef]);

  return (
    <ProductLink
      className={cn(styles.block, className)}
      list={list}
      to={{
        pathname: product.url,
        state: {
          hint: {
            type: "product",
            product,
            image: imageSrc,
          },
          breadcrumbLinks,
          qty: 1,
          list,
          position: Number(position) + 1,
        },
      }}
      product={{
        name: product.name,
        sku: product.sku,
        price: product.price,
        qty: 1,
        attributes: {
          manufacturer: product.attributes.manufacturer,
        },
        categories: product.categories,
      }}
      innerRef={gaRef}
      position={Number(position) + 1}
    >
      <div className={styles.header}>
        <ProductListMedia
          className={styles.imageWrapper}
          product={product}
          getCurrentImageSrc={x => setImageSrc(x)}
        />
      </div>

      <div className={styles.body}>
        <div className={styles.top}>
          <span className={styles.name}>{product.name}</span>
          <p className={styles.brand}>{product.attributes.manufacturer}</p>
          <div
            dangerouslySetInnerHTML={{ __html: product.attributes.description }}
            className={styles.description}
          />
        </div>

        <div className={styles.bottom}>
          <div className={styles.priceWrapper}>
            <p className={styles.price}>{formatPrice(price)}</p>
            {discount > 0 && discount < 100 && <p className={styles.msrp}>{`${t("PRODUCT.MSRP")} ${formatPrice(msrp)}`}</p>}
          </div>
          <Button variant="dark">{t("PRODUCT.READ_MORE")}</Button>
        </div>
      </div>
    </ProductLink>
  );
};

export const DummyCard = ({ className }: { className?: string }): React$Node => (
  <div className={cn(className, styles.block, styles.dummy)}>
    <div className={styles.imageWrapper}>
      <div className={styles.image}>
        <Square />
      </div>
    </div>

    <div className={styles.body}>
      <p className={styles.brand}>&nbsp;</p>
      <span className={styles.name}>&nbsp;</span>

      <div>
        <p className={styles.price}>&nbsp;</p>
        <Button variant="dark">&nbsp;</Button>
      </div>
    </div>
  </div>
);

export default ProductCard;
